import { thunk, action } from 'easy-peasy';
import { fetchQuery } from 'utils/relay';
import { TopUnreadNotifications } from 'components/notifications/graphql/query/TopUnreadNotifications';

import { isEmpty } from 'utils/utils';
import CHOICES from 'CHOICES';
import { AUTH_TOKEN } from 'utils/constants';

const Store = {
	topUnread: [],
	currentUser: null,
	authToken: window.localStorage.getItem(AUTH_TOKEN),
	showProPlanModal: false,
	isNameUpdated: false,

	setIsNameUpdated: action((state) => {
		state.isNameUpdated = !state.isNameUpdated;
	}),

	setAuthToken: action((state, authToken) => {
		state.authToken = authToken;
	}),

	setCurrentUser: action((state, currentUser) => {
		let cu = JSON.parse(JSON.stringify(currentUser));
		if (cu !== null) {
			cu.isAdmin = cu.type === CHOICES.UserTypeOption.ADMIN;
			cu.isClientAdmin = cu.type === CHOICES.UserTypeOption.CLIENT_ADMIN;
			cu.isClientUser = cu.type === CHOICES.UserTypeOption.CLIENT_USER;
			cu.isOperations = cu.type === CHOICES.UserTypeOption.OPERATIONS;
			cu.isSales = cu.type === CHOICES.UserTypeOption.SALES;
		}
		state.currentUser = cu;
	}),

	updateAuthToken: thunk((actions, authToken) => {
		window.localStorage.setItem(AUTH_TOKEN, authToken);
		actions.setAuthToken(authToken);
	}),

	clearAuthToken: thunk((actions) => {
		window.localStorage.clear();
		actions.setAuthToken(null);
		actions.setCurrentUser(null);
	}),

	fetchTopUnread: thunk(async (actions) => {
		fetchQuery(TopUnreadNotifications).then(({ systemNotificationsTopUnread }) => {
			if (!isEmpty(systemNotificationsTopUnread)) {
				actions.setTopUnread(systemNotificationsTopUnread);
			}
		});
	}),

	setTopUnread: action((state, payload) => {
		state.topUnread = payload;
	}),

	removeNotification: action((state, payload) => {
		const index = state.topUnread.findIndex((item) => item.id === payload.id);
		if (index > -1) {
			state.topUnread.splice(index, 1);
		}
	}),

	setShowProPlanModal: action((state, payload) => {
		state.showProPlanModal = payload;
	})
};

export default Store;
