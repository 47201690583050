/**
 * @flow
 * @relayHash 0e746fe1bb6f671588a9556fe1d699e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TopUnreadNotificationsQueryVariables = {||};
export type TopUnreadNotificationsQueryResponse = {|
  +systemNotificationsTopUnread: ?$ReadOnlyArray<?{|
    +id: string,
    +description: ?string,
    +title: ?string,
    +createdAt: any,
    +url: ?string,
    +user: {|
      +id: string,
      +name: ?string,
    |},
    +isRead: boolean,
  |}>
|};
export type TopUnreadNotificationsQuery = {|
  variables: TopUnreadNotificationsQueryVariables,
  response: TopUnreadNotificationsQueryResponse,
|};
*/


/*
query TopUnreadNotificationsQuery {
  systemNotificationsTopUnread {
    id
    description
    title
    createdAt
    url
    user {
      id
      name
    }
    isRead
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "systemNotificationsTopUnread",
    "storageKey": null,
    "args": null,
    "concreteType": "SystemNotificationType",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isRead",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TopUnreadNotificationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TopUnreadNotificationsQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TopUnreadNotificationsQuery",
    "id": null,
    "text": "query TopUnreadNotificationsQuery {\n  systemNotificationsTopUnread {\n    id\n    description\n    title\n    createdAt\n    url\n    user {\n      id\n      name\n    }\n    isRead\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb4fc0199de85d733ea825ce4884000b';
module.exports = node;
