/* eslint-disable no-undef */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import * as Sentry from '@sentry/react';
import * as serviceWorker from 'sw';
import Loader from 'components/loader';
import { createStore, StoreProvider } from 'easy-peasy';
import { lazyLoader } from 'utils/componentLoader';
import Store from 'store';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: 'https://3212adedde784cdd916bdf35d2d3d102@o389634.ingest.sentry.io/5228043' });
}

const store = createStore(Store);

const LazyApp = lazyLoader(() => import('App'));

const App = () => {
	return (
		<StoreProvider store={store}>
			<Suspense fallback={<Loader />}>
				<LazyApp />
			</Suspense>
		</StoreProvider>
	);
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// if ("serviceWorker" in navigator) {
// 	navigator.serviceWorker
// 		.register("../firebase-messaging-sw.js")
// 		.then(function (registration) {
// 			console.log("Registration successful, scope is:", registration.scope);
// 		})
// 		.catch(function (err) {
// 			console.log("Service worker registration failed, error:", err);
// 		});
// }
