import { lazy } from 'react';

export const componentLoader = (lazyComponent, attemptsLeft = 4) => {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				// let us retry after 1500 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
				}, 1500);
			});
	});
};

export const lazyLoader = (component) => {
	return lazy(() => componentLoader(component));
};
