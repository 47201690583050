// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py eb_client
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// enquirybot/contribs/management/commands/eb_client.py

const CHOICES = {
  "Access": {
    "ALLOWED_URL": 2,
    "ALLOW_ALL": 3,
    "BLOCKED_SLUG": 1,
    "BLOCKED_URL": 0,
    "CHOICES": {
      "0": "Blocked Url",
      "1": "Blocked Slug",
      "2": "Allowed Url",
      "3": "Allow All"
    },
    "CHOICE_LIST": [
      {
        "label": "Blocked Url",
        "value": 0
      },
      {
        "label": "Blocked Slug",
        "value": 1
      },
      {
        "label": "Allowed Url",
        "value": 2
      },
      {
        "label": "Allow All",
        "value": 3
      }
    ]
  },
  "ActionChoices": {
    "CALL": 1,
    "CHOICES": {
      "1": "CALL",
      "2": "Email",
      "3": "Meeting"
    },
    "CHOICE_LIST": [
      {
        "label": "CALL",
        "value": 1
      },
      {
        "label": "Email",
        "value": 2
      },
      {
        "label": "Meeting",
        "value": 3
      }
    ],
    "EMAIL": 2,
    "MEETING": 3
  },
  "ActionStatus": {
    "CHOICES": {
      "1": "IN PROGRESS",
      "2": "OVERDUE",
      "3": "COMPLETED"
    },
    "CHOICE_LIST": [
      {
        "label": "IN PROGRESS",
        "value": 1
      },
      {
        "label": "OVERDUE",
        "value": 2
      },
      {
        "label": "COMPLETED",
        "value": 3
      }
    ],
    "COMPLETE": 3,
    "IN_PROGRESS": 1,
    "OVERDUE": 2
  },
  "AutoResponderChoice": {
    "CHOICES": {
      "1": "Email",
      "2": "Sms"
    },
    "CHOICE_LIST": [
      {
        "label": "Email",
        "value": 1
      },
      {
        "label": "Sms",
        "value": 2
      }
    ],
    "EMAIL": 1,
    "SMS": 2
  },
  "AutomationChoice": {
    "ASSIGN_ENQUIRY": 6,
    "CHOICES": {
      "1": "External Email",
      "2": "Internal Email",
      "3": "Move Card",
      "4": "Move to another pipeline",
      "5": "Delay Timer",
      "6": "Assign enquiry",
      "7": "Note",
      "8": "Task",
      "9": "Zapier",
      "10": "External Sms",
      "11": "Internal Sms",
      "12": "salesforce",
      "13": "Pabau",
      "14": "Pabau Client"
    },
    "CHOICE_LIST": [
      {
        "label": "External Email",
        "value": 1
      },
      {
        "label": "Internal Email",
        "value": 2
      },
      {
        "label": "Move Card",
        "value": 3
      },
      {
        "label": "Move to another pipeline",
        "value": 4
      },
      {
        "label": "Delay Timer",
        "value": 5
      },
      {
        "label": "Assign enquiry",
        "value": 6
      },
      {
        "label": "Note",
        "value": 7
      },
      {
        "label": "Task",
        "value": 8
      },
      {
        "label": "Zapier",
        "value": 9
      },
      {
        "label": "External Sms",
        "value": 10
      },
      {
        "label": "Internal Sms",
        "value": 11
      },
      {
        "label": "salesforce",
        "value": 12
      },
      {
        "label": "Pabau",
        "value": 13
      },
      {
        "label": "Pabau Client",
        "value": 14
      }
    ],
    "DELAY_TIMER": 5,
    "EXTERNAL_EMAIL": 1,
    "EXTERNAL_SMS": 10,
    "INTERNAL_EMAIL": 2,
    "INTERNAL_SMS": 11,
    "MOVE_CARD": 3,
    "MOVE_TO_ANOTHER_PIPELINE": 4,
    "NOTE": 7,
    "PABAU": 13,
    "PABAU_CLIENT": 14,
    "SALES_FORCE": 12,
    "TASK": 8,
    "ZAPIER": 9
  },
  "BillingFrequency": {
    "CHOICES": {
      "1": "ONE TIME",
      "2": "RECURRING"
    },
    "CHOICE_LIST": [
      {
        "label": "ONE TIME",
        "value": 1
      },
      {
        "label": "RECURRING",
        "value": 2
      }
    ],
    "ONE_TIME": 1,
    "RECURRING": 2
  },
  "BotEventChoice": {
    "CHOICES": {
      "1": "Enquiry Started",
      "2": "Enquiry Completed",
      "3": "Email Captured",
      "4": "Phone Number Captured",
      "5": "Dismissed"
    },
    "CHOICE_LIST": [
      {
        "label": "Enquiry Started",
        "value": 1
      },
      {
        "label": "Enquiry Completed",
        "value": 2
      },
      {
        "label": "Email Captured",
        "value": 3
      },
      {
        "label": "Phone Number Captured",
        "value": 4
      },
      {
        "label": "Dismissed",
        "value": 5
      }
    ],
    "DISMISSED": 5,
    "EMAIL_CAPTURED": 3,
    "ENQUIRY_COMPLETED": 2,
    "ENQUIRY_STARTED": 1,
    "PHONE_NUMBER_CAPTURED": 4
  },
  "BotPlacement": {
    "CENTER": 3,
    "CHOICES": {
      "1": "Left",
      "2": "Right",
      "3": "Center"
    },
    "CHOICE_LIST": [
      {
        "label": "Left",
        "value": 1
      },
      {
        "label": "Right",
        "value": 2
      },
      {
        "label": "Center",
        "value": 3
      }
    ],
    "LEFT": 1,
    "RIGHT": 2
  },
  "BotSpeed": {
    "CHOICES": {
      "0": "Instant",
      "1": "Fast",
      "2": "Medium",
      "3": "Slow"
    },
    "CHOICE_LIST": [
      {
        "label": "Instant",
        "value": 0
      },
      {
        "label": "Fast",
        "value": 1
      },
      {
        "label": "Medium",
        "value": 2
      },
      {
        "label": "Slow",
        "value": 3
      }
    ],
    "FAST": 1,
    "INSTANT": 0,
    "MEDIUM": 2,
    "SLOW": 3
  },
  "BotStatus": {
    "ARCHIVED": 5,
    "CHOICES": {
      "1": "Draft",
      "2": "Review",
      "3": "Declined",
      "4": "Published",
      "5": "Archived",
      "6": "Deleted"
    },
    "CHOICE_LIST": [
      {
        "label": "Draft",
        "value": 1
      },
      {
        "label": "Review",
        "value": 2
      },
      {
        "label": "Declined",
        "value": 3
      },
      {
        "label": "Published",
        "value": 4
      },
      {
        "label": "Archived",
        "value": 5
      },
      {
        "label": "Deleted",
        "value": 6
      }
    ],
    "DECLINED": 3,
    "DELETED": 6,
    "DRAFT": 1,
    "PUBLISHED": 4,
    "REVIEW": 2
  },
  "BotStatusChoice": {
    "CHOICES": {
      "1": "IN_DEVELOPMENT",
      "2": "LIVE",
      "3": "PAUSED",
      "4": "DOWN",
      "5": "OFF"
    },
    "CHOICE_LIST": [
      {
        "label": "IN_DEVELOPMENT",
        "value": 1
      },
      {
        "label": "LIVE",
        "value": 2
      },
      {
        "label": "PAUSED",
        "value": 3
      },
      {
        "label": "DOWN",
        "value": 4
      },
      {
        "label": "OFF",
        "value": 5
      }
    ],
    "DOWN": 4,
    "IN_DEVELOPMENT": 1,
    "LIVE": 2,
    "OFF": 5,
    "PAUSED": 3
  },
  "BotTemplate": {
    "BOT": 1,
    "CHOICES": {
      "0": "Client Bot",
      "1": "Bot Template",
      "2": "Step Template"
    },
    "CHOICE_LIST": [
      {
        "label": "Client Bot",
        "value": 0
      },
      {
        "label": "Bot Template",
        "value": 1
      },
      {
        "label": "Step Template",
        "value": 2
      }
    ],
    "NONE": 0,
    "STEP": 2
  },
  "BotTestStatus": {
    "CHOICES": {
      "1": "In Progress",
      "2": "Paused",
      "3": "Completed",
      "4": "CREATED"
    },
    "CHOICE_LIST": [
      {
        "label": "In Progress",
        "value": 1
      },
      {
        "label": "Paused",
        "value": 2
      },
      {
        "label": "Completed",
        "value": 3
      },
      {
        "label": "CREATED",
        "value": 4
      }
    ],
    "COMPLETED": 3,
    "CREATED": 4,
    "IN_PROGRESS": 1,
    "PAUSED": 2
  },
  "BotVersionChoices": {
    "CHOICES": {
      "1": "Test Running",
      "2": "RUNNING",
      "3": "Paused"
    },
    "CHOICE_LIST": [
      {
        "label": "Test Running",
        "value": 1
      },
      {
        "label": "RUNNING",
        "value": 2
      },
      {
        "label": "Paused",
        "value": 3
      }
    ],
    "PAUSED": 3,
    "RUNNING": 2,
    "TEST_RUNNING": 1
  },
  "BrowserType": {
    "CHOICES": {
      "1": "SAFARI",
      "2": "CHROME",
      "3": "FIREFOX"
    },
    "CHOICE_LIST": [
      {
        "label": "SAFARI",
        "value": 1
      },
      {
        "label": "CHROME",
        "value": 2
      },
      {
        "label": "FIREFOX",
        "value": 3
      }
    ],
    "CHROME": 2,
    "FIREFOX": 3,
    "SAFARI": 1
  },
  "BusinessChannelChoices": {
    "CHOICES": {
      "1": "Facebook",
      "2": "Instagram",
      "3": "Whatsapp business",
      "4": "Google business messages",
      "5": "Web forms",
      "6": "Facebook lead gen"
    },
    "CHOICE_LIST": [
      {
        "label": "Facebook",
        "value": 1
      },
      {
        "label": "Instagram",
        "value": 2
      },
      {
        "label": "Whatsapp business",
        "value": 3
      },
      {
        "label": "Google business messages",
        "value": 4
      },
      {
        "label": "Web forms",
        "value": 5
      },
      {
        "label": "Facebook lead gen",
        "value": 6
      }
    ],
    "FACEBOOK": 1,
    "FACEBOOK_LEADGEN": 6,
    "GOOGLE_BUSINESS_MESSAGES": 4,
    "INSTAGRAM": 2,
    "WEB_FORMS": 5,
    "WHATSAPP_BUSINESS": 3
  },
  "CardChannelChoices": {
    "BOT": 1,
    "CHOICES": {
      "1": "Bot",
      "2": "Facebook",
      "3": "Instagram",
      "4": "Manually added",
      "5": "Web Forms",
      "6": "Facebook Lead Ads"
    },
    "CHOICE_LIST": [
      {
        "label": "Bot",
        "value": 1
      },
      {
        "label": "Facebook",
        "value": 2
      },
      {
        "label": "Instagram",
        "value": 3
      },
      {
        "label": "Manually added",
        "value": 4
      },
      {
        "label": "Web Forms",
        "value": 5
      },
      {
        "label": "Facebook Lead Ads",
        "value": 6
      }
    ],
    "FACEBOOK": 2,
    "FACEBOOK_LEADGEN": 6,
    "INSTAGRAM": 3,
    "MANUALLY_ADDED": 4,
    "WEB_FORMS": 5
  },
  "CardStatusChoice": {
    "CHOICES": {
      "1": "New",
      "2": "Open",
      "3": "Closed"
    },
    "CHOICE_LIST": [
      {
        "label": "New",
        "value": 1
      },
      {
        "label": "Open",
        "value": 2
      },
      {
        "label": "Closed",
        "value": 3
      }
    ],
    "CLOSE": 3,
    "NEW": 1,
    "OPEN": 2
  },
  "ClientAccountStatusChoice": {
    "ACCOUNT_BLOCKED": 7,
    "ACCOUNT_CLOSED": 8,
    "ACCOUNT_IN_REVIEW": 5,
    "ACCOUNT_ON_STOP": 6,
    "CHOICES": {
      "1": "PAYMENT_LINK_SENT",
      "2": "PAYMENT_DETAILS_SUBMITTED",
      "3": "SUBSCRIPTION_STARTED",
      "4": "PAUSED",
      "5": "ACCOUNT_IN_REVIEW",
      "6": "ACCOUNT_ON_STOP",
      "7": "ACCOUNT_BLOCKED",
      "8": "ACCOUNT_CLOSED",
      "9": "ONBOARDED"
    },
    "CHOICE_LIST": [
      {
        "label": "PAYMENT_LINK_SENT",
        "value": 1
      },
      {
        "label": "PAYMENT_DETAILS_SUBMITTED",
        "value": 2
      },
      {
        "label": "SUBSCRIPTION_STARTED",
        "value": 3
      },
      {
        "label": "PAUSED",
        "value": 4
      },
      {
        "label": "ACCOUNT_IN_REVIEW",
        "value": 5
      },
      {
        "label": "ACCOUNT_ON_STOP",
        "value": 6
      },
      {
        "label": "ACCOUNT_BLOCKED",
        "value": 7
      },
      {
        "label": "ACCOUNT_CLOSED",
        "value": 8
      },
      {
        "label": "ONBOARDED",
        "value": 9
      }
    ],
    "ONBOARDED": 9,
    "PAUSED": 4,
    "PAYMENT_DETAILS_SUBMITTED": 2,
    "PAYMENT_LINK_SENT": 1,
    "SUBSCRIPTION_STARTED": 3
  },
  "ClientCurrency": {
    "AUD": "AUD",
    "CHOICES": {
      "AUD": "AUD",
      "EUR": "Euro",
      "GBP": "Sterling",
      "USD": "Dollar"
    },
    "CHOICE_LIST": [
      {
        "label": "AUD",
        "value": "AUD"
      },
      {
        "label": "Euro",
        "value": "EUR"
      },
      {
        "label": "Sterling",
        "value": "GBP"
      },
      {
        "label": "Dollar",
        "value": "USD"
      }
    ],
    "DOLLAR": "USD",
    "EURO": "EUR",
    "STERLING": "GBP"
  },
  "ClientStatus": {
    "ARCHIVED": 5,
    "CHOICES": {
      "1": "Draft",
      "2": "Request Onboard",
      "3": "Onboarded",
      "4": "Deleted",
      "5": "Archived",
      "6": "Paused"
    },
    "CHOICE_LIST": [
      {
        "label": "Draft",
        "value": 1
      },
      {
        "label": "Request Onboard",
        "value": 2
      },
      {
        "label": "Onboarded",
        "value": 3
      },
      {
        "label": "Deleted",
        "value": 4
      },
      {
        "label": "Archived",
        "value": 5
      },
      {
        "label": "Paused",
        "value": 6
      }
    ],
    "DELETED": 4,
    "DRAFT": 1,
    "ONBOARDED": 3,
    "PAUSED": 6,
    "REQUEST_ONBOARD": 2
  },
  "ClientType": {
    "CHOICES": {
      "1": "CLIENT",
      "2": "PARTNER"
    },
    "CHOICE_LIST": [
      {
        "label": "CLIENT",
        "value": 1
      },
      {
        "label": "PARTNER",
        "value": 2
      }
    ],
    "CLIENT": 1,
    "PARTNER": 2
  },
  "CommissionPeriod": {
    "CHOICES": {
      "1": "LIFETIME",
      "2": "LIMITEDPERIOD"
    },
    "CHOICE_LIST": [
      {
        "label": "LIFETIME",
        "value": 1
      },
      {
        "label": "LIMITEDPERIOD",
        "value": 2
      }
    ],
    "LIFETIME": 1,
    "LIMITEDPERIOD": 2
  },
  "CommissionType": {
    "CHOICES": {
      "1": "FLATRATE",
      "2": "PERCENTAGE"
    },
    "CHOICE_LIST": [
      {
        "label": "FLATRATE",
        "value": 1
      },
      {
        "label": "PERCENTAGE",
        "value": 2
      }
    ],
    "FLATRATE": 1,
    "PERCENTAGE": 2
  },
  "CreatedDateChoices": {
    "CHOICES": {
      "1": "Today",
      "2": "This Week",
      "3": "Last Week",
      "4": "This Month",
      "5": "Last Month",
      "6": "Last 3 Months",
      "7": "Custom Range"
    },
    "CHOICE_LIST": [
      {
        "label": "Today",
        "value": 1
      },
      {
        "label": "This Week",
        "value": 2
      },
      {
        "label": "Last Week",
        "value": 3
      },
      {
        "label": "This Month",
        "value": 4
      },
      {
        "label": "Last Month",
        "value": 5
      },
      {
        "label": "Last 3 Months",
        "value": 6
      },
      {
        "label": "Custom Range",
        "value": 7
      }
    ],
    "CUSTOM_RANGE": 7,
    "LAST_3_MONTH": 6,
    "LAST_MONTH": 5,
    "LAST_WEEK": 3,
    "THIS_MONTH": 4,
    "THIS_WEEK": 2,
    "TODAY": 1
  },
  "Currency": {
    "AUD": 3,
    "CHOICES": {
      "1": "USD",
      "2": "GBP",
      "3": "AUD",
      "4": "EUR"
    },
    "CHOICE_LIST": [
      {
        "label": "USD",
        "value": 1
      },
      {
        "label": "GBP",
        "value": 2
      },
      {
        "label": "AUD",
        "value": 3
      },
      {
        "label": "EUR",
        "value": 4
      }
    ],
    "EURO": 4,
    "GBP": 2,
    "USD": 1
  },
  "CustomType": {
    "CHOICES": {
      "1": "Drop Down",
      "2": "Date",
      "3": "Text",
      "4": "Tick Box",
      "5": "Number"
    },
    "CHOICE_LIST": [
      {
        "label": "Drop Down",
        "value": 1
      },
      {
        "label": "Date",
        "value": 2
      },
      {
        "label": "Text",
        "value": 3
      },
      {
        "label": "Tick Box",
        "value": 4
      },
      {
        "label": "Number",
        "value": 5
      }
    ],
    "DATE": 2,
    "DROP_DOWN": 1,
    "NUMBER": 5,
    "TEXT": 3,
    "TICK_BOX": 4
  },
  "DayTypeChoice": {
    "CHOICES": {
      "1": "MONDAY",
      "2": "TUESDAY",
      "3": "WEDNESDAY",
      "4": "THURSDAY",
      "5": "FRIDAY",
      "6": "SATURDAY",
      "7": "SUNDAY"
    },
    "CHOICE_LIST": [
      {
        "label": "MONDAY",
        "value": 1
      },
      {
        "label": "TUESDAY",
        "value": 2
      },
      {
        "label": "WEDNESDAY",
        "value": 3
      },
      {
        "label": "THURSDAY",
        "value": 4
      },
      {
        "label": "FRIDAY",
        "value": 5
      },
      {
        "label": "SATURDAY",
        "value": 6
      },
      {
        "label": "SUNDAY",
        "value": 7
      }
    ],
    "FRIDAY": 5,
    "MONDAY": 1,
    "SATURDAY": 6,
    "SUNDAY": 7,
    "THURSDAY": 4,
    "TUESDAY": 2,
    "WEDNESDAY": 3
  },
  "DeviceChoices": {
    "ANDROID": 2,
    "CHOICES": {
      "1": "Ios",
      "2": "Android",
      "3": "Web"
    },
    "CHOICE_LIST": [
      {
        "label": "Ios",
        "value": 1
      },
      {
        "label": "Android",
        "value": 2
      },
      {
        "label": "Web",
        "value": 3
      }
    ],
    "IOS": 1,
    "WEB": 3
  },
  "DeviceType": {
    "CHOICES": {
      "1": "PHONE",
      "2": "TABLET",
      "3": "DESKTOP"
    },
    "CHOICE_LIST": [
      {
        "label": "PHONE",
        "value": 1
      },
      {
        "label": "TABLET",
        "value": 2
      },
      {
        "label": "DESKTOP",
        "value": 3
      }
    ],
    "DESKTOP": 3,
    "PHONE": 1,
    "TABLET": 2
  },
  "DiscountPeriod": {
    "CHOICES": {
      "1": "LIFETIME",
      "2": "LIMITEDPERIOD",
      "3": "NONE"
    },
    "CHOICE_LIST": [
      {
        "label": "LIFETIME",
        "value": 1
      },
      {
        "label": "LIMITEDPERIOD",
        "value": 2
      },
      {
        "label": "NONE",
        "value": 3
      }
    ],
    "LIFETIME": 1,
    "LIMITEDPERIOD": 2,
    "NONE": 3
  },
  "DiscountType": {
    "CHOICES": {
      "1": "FLATRATE",
      "2": "PERCENTAGE",
      "3": "FREEMONTHS",
      "4": "NONE"
    },
    "CHOICE_LIST": [
      {
        "label": "FLATRATE",
        "value": 1
      },
      {
        "label": "PERCENTAGE",
        "value": 2
      },
      {
        "label": "FREEMONTHS",
        "value": 3
      },
      {
        "label": "NONE",
        "value": 4
      }
    ],
    "FLATRATE": 1,
    "FREEMONTHS": 3,
    "NONE": 4,
    "PERCENTAGE": 2
  },
  "DomainRecordChoices": {
    "CHOICES": {
      "1": "Cname",
      "2": "Txt"
    },
    "CHOICE_LIST": [
      {
        "label": "Cname",
        "value": 1
      },
      {
        "label": "Txt",
        "value": 2
      }
    ],
    "CNAME": 1,
    "TXT": 2
  },
  "EmailReminderChoices": {
    "AT_THE_TIME_OF_THE_TASK": 4,
    "CHOICES": {
      "1": "No Reminder",
      "2": "1 hour before",
      "3": "1 day before",
      "4": "At the time of the task",
      "5": "5 minutes before",
      "6": "30 minutes before"
    },
    "CHOICE_LIST": [
      {
        "label": "No Reminder",
        "value": 1
      },
      {
        "label": "1 hour before",
        "value": 2
      },
      {
        "label": "1 day before",
        "value": 3
      },
      {
        "label": "At the time of the task",
        "value": 4
      },
      {
        "label": "5 minutes before",
        "value": 5
      },
      {
        "label": "30 minutes before",
        "value": 6
      }
    ],
    "FIVE_MINUTES_BEFORE": 5,
    "NO_REMINDER": 1,
    "ONE_DAY_BEFORE": 3,
    "ONE_HOUR_BEFORE": 2,
    "THIRTY_MINUTES_BEFORE": 6
  },
  "FbAttachmentTypeChoices": {
    "AUDIO": "audio",
    "CHOICES": {
      "application/pdf": "Pdf",
      "audio": "Audio",
      "file": "File",
      "gif": "Gif",
      "image": "Image",
      "image/gif": "Gif",
      "image/jpeg": "Image",
      "text/csv": "Csv",
      "video": "Video",
      "video/mp4": "Video"
    },
    "CHOICE_LIST": [
      {
        "label": "Pdf",
        "value": "application/pdf"
      },
      {
        "label": "Audio",
        "value": "audio"
      },
      {
        "label": "File",
        "value": "file"
      },
      {
        "label": "Gif",
        "value": "gif"
      },
      {
        "label": "Image",
        "value": "image"
      },
      {
        "label": "Gif",
        "value": "image/gif"
      },
      {
        "label": "Image",
        "value": "image/jpeg"
      },
      {
        "label": "Csv",
        "value": "text/csv"
      },
      {
        "label": "Video",
        "value": "video"
      },
      {
        "label": "Video",
        "value": "video/mp4"
      }
    ],
    "FILE": "file",
    "FILE_1": "application/pdf",
    "FILE_2": "text/csv",
    "GIF": "gif",
    "GIF_1": "image/gif",
    "IMAGE": "image",
    "IMAGE_1": "image/jpeg",
    "VIDEO": "video",
    "VIDEO_1": "video/mp4"
  },
  "FbPermissionStatusChoices": {
    "CHOICES": {
      "declined": "Declined",
      "expired": "Expired",
      "granted": "Granted"
    },
    "CHOICE_LIST": [
      {
        "label": "Declined",
        "value": "declined"
      },
      {
        "label": "Expired",
        "value": "expired"
      },
      {
        "label": "Granted",
        "value": "granted"
      }
    ],
    "DECLINED": "declined",
    "EXPIRED": "expired",
    "GRANTED": "granted"
  },
  "Gateway": {
    "CHOICES": {
      "1": "GOCARDLESS",
      "2": "STRIPE"
    },
    "CHOICE_LIST": [
      {
        "label": "GOCARDLESS",
        "value": 1
      },
      {
        "label": "STRIPE",
        "value": 2
      }
    ],
    "GOCARDLESS": 1,
    "STRIPE": 2
  },
  "GroupKind": {
    "AND": 1,
    "CHOICES": {
      "1": "And",
      "2": "Or"
    },
    "CHOICE_LIST": [
      {
        "label": "And",
        "value": 1
      },
      {
        "label": "Or",
        "value": 2
      }
    ],
    "OR": 2
  },
  "InvoiceChoice": {
    "CHOICES": {
      "1": "Subscription",
      "2": "One time payment"
    },
    "CHOICE_LIST": [
      {
        "label": "Subscription",
        "value": 1
      },
      {
        "label": "One time payment",
        "value": 2
      }
    ],
    "ONE_TIME_PAYMENT": 2,
    "SUBSCRIPTION": 1
  },
  "JourneyType": {
    "CHOICES": {
      "0": "NONE",
      "2": "DATA_CAPTURED",
      "3": "ENQUIRED"
    },
    "CHOICE_LIST": [
      {
        "label": "NONE",
        "value": 0
      },
      {
        "label": "DATA_CAPTURED",
        "value": 2
      },
      {
        "label": "ENQUIRED",
        "value": 3
      }
    ],
    "DATA_CAPTURED": 2,
    "ENQUIRED": 3,
    "NONE": 0
  },
  "LeadChoice": {
    "CHOICES": {
      "1": "REFERRAL",
      "2": "DIRECT"
    },
    "CHOICE_LIST": [
      {
        "label": "REFERRAL",
        "value": 1
      },
      {
        "label": "DIRECT",
        "value": 2
      }
    ],
    "DIRECT": 2,
    "REFERRAL": 1
  },
  "LeadSourceCategoryChoice": {
    "CHOICES": {
      "1": "Organic Search",
      "2": "Paid Search",
      "3": "Organic Social",
      "4": "Paid Social",
      "5": "Direct",
      "6": "Referral",
      "7": "Unknown"
    },
    "CHOICE_LIST": [
      {
        "label": "Organic Search",
        "value": 1
      },
      {
        "label": "Paid Search",
        "value": 2
      },
      {
        "label": "Organic Social",
        "value": 3
      },
      {
        "label": "Paid Social",
        "value": 4
      },
      {
        "label": "Direct",
        "value": 5
      },
      {
        "label": "Referral",
        "value": 6
      },
      {
        "label": "Unknown",
        "value": 7
      }
    ],
    "DIRECT": 5,
    "ORGANIC_SEARCH": 1,
    "ORGANIC_SOCIAL": 3,
    "PAID_SEARCH": 2,
    "PAID_SOCIAL": 4,
    "REFERRAL": 6,
    "UNKNOWN": 7
  },
  "LeadStatusChoice": {
    "ACTIVE": 4,
    "CHOICES": {
      "1": "Won",
      "2": "Lost",
      "3": "Expired",
      "4": "Active",
      "5": "In Progress"
    },
    "CHOICE_LIST": [
      {
        "label": "Won",
        "value": 1
      },
      {
        "label": "Lost",
        "value": 2
      },
      {
        "label": "Expired",
        "value": 3
      },
      {
        "label": "Active",
        "value": 4
      },
      {
        "label": "In Progress",
        "value": 5
      }
    ],
    "EXPIRED": 3,
    "IN_PROGRESS": 5,
    "LOST": 2,
    "WON": 1
  },
  "LicensePlanType": {
    "CHOICES": {
      "1": "STARTER",
      "2": "PRO"
    },
    "CHOICE_LIST": [
      {
        "label": "STARTER",
        "value": 1
      },
      {
        "label": "PRO",
        "value": 2
      }
    ],
    "PRO": 2,
    "STARTER": 1
  },
  "LicenseStatusChoices": {
    "ACTIVE": 1,
    "CANCELLATION_REQUEST": 5,
    "CANCELLED": 2,
    "CHOICES": {
      "1": "Active",
      "2": "Cancelled",
      "3": "Paused",
      "4": "Expired",
      "5": "Cancellation Request",
      "6": "Paused Requested"
    },
    "CHOICE_LIST": [
      {
        "label": "Active",
        "value": 1
      },
      {
        "label": "Cancelled",
        "value": 2
      },
      {
        "label": "Paused",
        "value": 3
      },
      {
        "label": "Expired",
        "value": 4
      },
      {
        "label": "Cancellation Request",
        "value": 5
      },
      {
        "label": "Paused Requested",
        "value": 6
      }
    ],
    "EXPIRED": 4,
    "PAUSED": 3,
    "PAUSED_REQUESTED": 6
  },
  "MaterialChoice": {
    "CASE_STUDIES": 6,
    "CHOICES": {
      "1": "Presentation",
      "2": "Graphics",
      "3": "Demo_Link",
      "4": "Logos",
      "5": "Social_Posts",
      "6": "Case_Stuies"
    },
    "CHOICE_LIST": [
      {
        "label": "Presentation",
        "value": 1
      },
      {
        "label": "Graphics",
        "value": 2
      },
      {
        "label": "Demo_Link",
        "value": 3
      },
      {
        "label": "Logos",
        "value": 4
      },
      {
        "label": "Social_Posts",
        "value": 5
      },
      {
        "label": "Case_Stuies",
        "value": 6
      }
    ],
    "DEMO_LINK": 3,
    "GRAPHICS": 2,
    "LOGOS": 4,
    "PRESENTATION": 1,
    "SOCIAL_POSTS": 5
  },
  "NotificationTypeChoices": {
    "ACTION_ASSGINED_TO_YOU": 2,
    "ACTION_REMINDER": 3,
    "ASSINGED_TO_ENQUIRY": 1,
    "CHOICES": {
      "1": "ASSINGED_TO_ENQUIRY",
      "2": "ACTION_ASSGINED_TO_YOU",
      "3": "ACTION_REMINDER",
      "4": "TAGGED_IN_A_NOTE",
      "5": "NOTE_THREAD",
      "6": "SOCIAL_MESSAGE",
      "7": "WEB_FORMS",
      "8": "FACEBOOK_LEADGEN"
    },
    "CHOICE_LIST": [
      {
        "label": "ASSINGED_TO_ENQUIRY",
        "value": 1
      },
      {
        "label": "ACTION_ASSGINED_TO_YOU",
        "value": 2
      },
      {
        "label": "ACTION_REMINDER",
        "value": 3
      },
      {
        "label": "TAGGED_IN_A_NOTE",
        "value": 4
      },
      {
        "label": "NOTE_THREAD",
        "value": 5
      },
      {
        "label": "SOCIAL_MESSAGE",
        "value": 6
      },
      {
        "label": "WEB_FORMS",
        "value": 7
      },
      {
        "label": "FACEBOOK_LEADGEN",
        "value": 8
      }
    ],
    "FACEBOOK_LEADGEN": 8,
    "NOTE_THREAD": 5,
    "SOCIAL_MESSAGE": 6,
    "TAGGED_IN_A_NOTE": 4,
    "WEB_FORMS": 7
  },
  "OperatorKind": {
    "CHOICES": {
      "1": "=",
      "2": "!=",
      "3": ">",
      "4": ">=",
      "5": "<",
      "6": "<=",
      "7": "In",
      "8": "Not in",
      "9": "Contains",
      "10": "Does not Contain",
      "11": "Starts with",
      "12": "Ends With"
    },
    "CHOICE_LIST": [
      {
        "label": "=",
        "value": 1
      },
      {
        "label": "!=",
        "value": 2
      },
      {
        "label": ">",
        "value": 3
      },
      {
        "label": ">=",
        "value": 4
      },
      {
        "label": "<",
        "value": 5
      },
      {
        "label": "<=",
        "value": 6
      },
      {
        "label": "In",
        "value": 7
      },
      {
        "label": "Not in",
        "value": 8
      },
      {
        "label": "Contains",
        "value": 9
      },
      {
        "label": "Does not Contain",
        "value": 10
      },
      {
        "label": "Starts with",
        "value": 11
      },
      {
        "label": "Ends With",
        "value": 12
      }
    ],
    "CONTAINS": 9,
    "DOES_NOT_CONTAIN": 10,
    "ENDS_WITH": 12,
    "EQUAL": 1,
    "GREATER_THAN": 3,
    "GREATER_THAN_OR_EQUAL": 4,
    "IN": 7,
    "LESSER_THAN": 5,
    "LESSER_THAN_OR_EQUAL": 6,
    "NOT_EQUAL": 2,
    "NOT_IN": 8,
    "STARTS_WITH": 11
  },
  "OptionCategory": {
    "BUTTON": 2,
    "CHOICES": {
      "0": "Text",
      "1": "Image",
      "2": "Button",
      "3": "Others"
    },
    "CHOICE_LIST": [
      {
        "label": "Text",
        "value": 0
      },
      {
        "label": "Image",
        "value": 1
      },
      {
        "label": "Button",
        "value": 2
      },
      {
        "label": "Others",
        "value": 3
      }
    ],
    "IMAGE": 1,
    "OTHERS": 3,
    "TEXT": 0
  },
  "Partner": {
    "CHOICES": {
      "1": "ZAPIER",
      "2": "MAILCHIMP",
      "3": "STRIPE"
    },
    "CHOICE_LIST": [
      {
        "label": "ZAPIER",
        "value": 1
      },
      {
        "label": "MAILCHIMP",
        "value": 2
      },
      {
        "label": "STRIPE",
        "value": 3
      }
    ],
    "MAILCHIMP": 2,
    "STRIPE": 3,
    "ZAPIER": 1
  },
  "PartnerType": {
    "CHOICES": {
      "1": "Referral",
      "2": "Sales"
    },
    "CHOICE_LIST": [
      {
        "label": "Referral",
        "value": 1
      },
      {
        "label": "Sales",
        "value": 2
      }
    ],
    "REFERRAL": 1,
    "SALES": 2
  },
  "PaymentStatus": {
    "CHOICES": {
      "2": "FAILED",
      "3": "SUCCESS "
    },
    "CHOICE_LIST": [
      {
        "label": "FAILED",
        "value": 2
      },
      {
        "label": "SUCCESS ",
        "value": 3
      }
    ],
    "FAILED": 2,
    "SUCCESS": 3
  },
  "PhoneType": {
    "ANDRIOD": 1,
    "CHOICES": {
      "1": "ANDRIOD",
      "2": "IPHONE"
    },
    "CHOICE_LIST": [
      {
        "label": "ANDRIOD",
        "value": 1
      },
      {
        "label": "IPHONE",
        "value": 2
      }
    ],
    "IPHONE": 2
  },
  "PipelineChoices": {
    "CHOICES": {
      "1": "PARTNER",
      "2": "ENQUIRY_BOT"
    },
    "CHOICE_LIST": [
      {
        "label": "PARTNER",
        "value": 1
      },
      {
        "label": "ENQUIRY_BOT",
        "value": 2
      }
    ],
    "ENQUIRY_BOT": 2,
    "PARTNER": 1
  },
  "PlanPeriod": {
    "ANNUALLY": 3,
    "CHOICES": {
      "1": "Monthly",
      "2": "Quarterly",
      "3": "Annually"
    },
    "CHOICE_LIST": [
      {
        "label": "Monthly",
        "value": 1
      },
      {
        "label": "Quarterly",
        "value": 2
      },
      {
        "label": "Annually",
        "value": 3
      }
    ],
    "MONTHLY": 1,
    "QUARTERLY": 2
  },
  "ReplyChannelChoices": {
    "CHOICES": {
      "1": "Email",
      "2": "Facebook",
      "3": "Instagram",
      "4": "Sms",
      "5": "FORM_DATA",
      "6": "Facebook leads gen"
    },
    "CHOICE_LIST": [
      {
        "label": "Email",
        "value": 1
      },
      {
        "label": "Facebook",
        "value": 2
      },
      {
        "label": "Instagram",
        "value": 3
      },
      {
        "label": "Sms",
        "value": 4
      },
      {
        "label": "FORM_DATA",
        "value": 5
      },
      {
        "label": "Facebook leads gen",
        "value": 6
      }
    ],
    "EMAIL": 1,
    "FACEBOOK": 2,
    "FACEBOOK_LEADGEN": 6,
    "FORM_DATA": 5,
    "INSTAGRAM": 3,
    "SMS": 4
  },
  "ReplyTemplateChoices": {
    "CHOICES": {
      "1": "Email",
      "2": "Sms",
      "3": "Facebook",
      "4": "Instagram"
    },
    "CHOICE_LIST": [
      {
        "label": "Email",
        "value": 1
      },
      {
        "label": "Sms",
        "value": 2
      },
      {
        "label": "Facebook",
        "value": 3
      },
      {
        "label": "Instagram",
        "value": 4
      }
    ],
    "EMAIL": 1,
    "FACEBOOK": 3,
    "INSTAGRAM": 4,
    "SMS": 2
  },
  "RequestCategory": {
    "BOT_TEMPLATE": 2,
    "CHANGE_REQUEST": 3,
    "CHOICES": {
      "1": "Step Template",
      "2": "Bot Template",
      "3": "Change Request"
    },
    "CHOICE_LIST": [
      {
        "label": "Step Template",
        "value": 1
      },
      {
        "label": "Bot Template",
        "value": 2
      },
      {
        "label": "Change Request",
        "value": 3
      }
    ],
    "STEP_TEMPLATE": 1
  },
  "RequestState": {
    "CHOICES": {
      "1": "Pending",
      "2": "In Progress",
      "3": "Completed",
      "4": "Closed"
    },
    "CHOICE_LIST": [
      {
        "label": "Pending",
        "value": 1
      },
      {
        "label": "In Progress",
        "value": 2
      },
      {
        "label": "Completed",
        "value": 3
      },
      {
        "label": "Closed",
        "value": 4
      }
    ],
    "CLOSED": 4,
    "COMPLETED": 3,
    "IN_PROGRESS": 2,
    "PENDING": 1
  },
  "RescheduleTaskChoice": {
    "CHOICES": {
      "1": "Tomorrow",
      "2": "Next week",
      "3": "Next month"
    },
    "CHOICE_LIST": [
      {
        "label": "Tomorrow",
        "value": 1
      },
      {
        "label": "Next week",
        "value": 2
      },
      {
        "label": "Next month",
        "value": 3
      }
    ],
    "NEXT_MONTH": 3,
    "NEXT_WEEK": 2,
    "TOMORROW": 1
  },
  "RunOnTypeChoice": {
    "ANYTIME": 3,
    "CHOICES": {
      "1": "Weekdays",
      "2": "Weekends",
      "3": "Anytime"
    },
    "CHOICE_LIST": [
      {
        "label": "Weekdays",
        "value": 1
      },
      {
        "label": "Weekends",
        "value": 2
      },
      {
        "label": "Anytime",
        "value": 3
      }
    ],
    "WEEKDAYS": 1,
    "WEEKENDS": 2
  },
  "SearchCategoryChoice": {
    "ALL": 1,
    "CHOICES": {
      "1": "All",
      "2": "Name",
      "3": "Email",
      "4": "Enquiry type",
      "5": "Extra info field name",
      "6": "Extra info field data",
      "7": "Phone number"
    },
    "CHOICE_LIST": [
      {
        "label": "All",
        "value": 1
      },
      {
        "label": "Name",
        "value": 2
      },
      {
        "label": "Email",
        "value": 3
      },
      {
        "label": "Enquiry type",
        "value": 4
      },
      {
        "label": "Extra info field name",
        "value": 5
      },
      {
        "label": "Extra info field data",
        "value": 6
      },
      {
        "label": "Phone number",
        "value": 7
      }
    ],
    "EMAIL": 3,
    "ENQUIRY_TYPE": 4,
    "EXTRA_INFO_FIELD_DATA": 6,
    "EXTRA_INFO_FIELD_NAME": 5,
    "NAME": 2,
    "PHONE_NUMBER": 7
  },
  "SesMailVerificationStatusChoices": {
    "CHOICES": {
      "1": "PENDING",
      "2": "SUCCESS",
      "3": "FAILED",
      "4": "TEMPORARY_FAILURE",
      "5": "NOT_STARTED"
    },
    "CHOICE_LIST": [
      {
        "label": "PENDING",
        "value": 1
      },
      {
        "label": "SUCCESS",
        "value": 2
      },
      {
        "label": "FAILED",
        "value": 3
      },
      {
        "label": "TEMPORARY_FAILURE",
        "value": 4
      },
      {
        "label": "NOT_STARTED",
        "value": 5
      }
    ],
    "FAILED": 3,
    "NOT_STARTED": 5,
    "PENDING": 1,
    "SUCCESS": 2,
    "TEMPORARY_FAILURE": 4
  },
  "SesRegionChoices": {
    "AP_SOUTHEAST_2": "ap-southeast-2",
    "AP_SOUTH_1": "ap-south-1",
    "CHOICES": {
      "ap-south-1": "AP_SOUTH_1",
      "ap-southeast-2": "AP_SOUTHEAST_2",
      "eu-central-1": "EU_CENTRAL_1",
      "eu-west-1": "EU_WEST_1",
      "eu-west-2": "EU_WEST_2",
      "us-east-1": "US_EAST_1",
      "us-west-2": "US_WEST_2"
    },
    "CHOICE_LIST": [
      {
        "label": "AP_SOUTH_1",
        "value": "ap-south-1"
      },
      {
        "label": "AP_SOUTHEAST_2",
        "value": "ap-southeast-2"
      },
      {
        "label": "EU_CENTRAL_1",
        "value": "eu-central-1"
      },
      {
        "label": "EU_WEST_1",
        "value": "eu-west-1"
      },
      {
        "label": "EU_WEST_2",
        "value": "eu-west-2"
      },
      {
        "label": "US_EAST_1",
        "value": "us-east-1"
      },
      {
        "label": "US_WEST_2",
        "value": "us-west-2"
      }
    ],
    "EU_CENTRAL_1": "eu-central-1",
    "EU_WEST_1": "eu-west-1",
    "EU_WEST_2": "eu-west-2",
    "US_EAST_1": "us-east-1",
    "US_WEST_2": "us-west-2"
  },
  "SessionState": {
    "ABANDONED": 6,
    "CHOICES": {
      "1": "Created",
      "2": "In Progress",
      "3": "Disconnected",
      "4": "Terminated",
      "5": "Completed",
      "6": "Abandoned",
      "7": "Invalid"
    },
    "CHOICE_LIST": [
      {
        "label": "Created",
        "value": 1
      },
      {
        "label": "In Progress",
        "value": 2
      },
      {
        "label": "Disconnected",
        "value": 3
      },
      {
        "label": "Terminated",
        "value": 4
      },
      {
        "label": "Completed",
        "value": 5
      },
      {
        "label": "Abandoned",
        "value": 6
      },
      {
        "label": "Invalid",
        "value": 7
      }
    ],
    "COMPLETED": 5,
    "CREATED": 1,
    "DISCONNECTED": 3,
    "INVALID": 7,
    "IN_PROGRESS": 2,
    "TERMINATED": 4
  },
  "SortByChoice": {
    "CHOICES": {
      "1": "Newest first",
      "2": "Highest value first"
    },
    "CHOICE_LIST": [
      {
        "label": "Newest first",
        "value": 1
      },
      {
        "label": "Highest value first",
        "value": 2
      }
    ],
    "HIGHEST_VALUE_FIRST": 2,
    "NEWEST_FIRST": 1
  },
  "StageKind": {
    "BOT_LIVE": 13,
    "CHOICES": {
      "1": "Started",
      "2": "Won",
      "3": "Lost",
      "4": "Custom",
      "5": "Contacting",
      "6": "DATA CAPTURE",
      "7": "SALES CALL BOOKED",
      "8": "SEND_QUOTE",
      "9": "IN DEVELOPMENT",
      "10": "REVIEW",
      "11": "SEND TO CLIENT",
      "12": "READY TO GO LIVE",
      "13": "BOT LIVE",
      "14": "FUTURE FOLLOW UP"
    },
    "CHOICE_LIST": [
      {
        "label": "Started",
        "value": 1
      },
      {
        "label": "Won",
        "value": 2
      },
      {
        "label": "Lost",
        "value": 3
      },
      {
        "label": "Custom",
        "value": 4
      },
      {
        "label": "Contacting",
        "value": 5
      },
      {
        "label": "DATA CAPTURE",
        "value": 6
      },
      {
        "label": "SALES CALL BOOKED",
        "value": 7
      },
      {
        "label": "SEND_QUOTE",
        "value": 8
      },
      {
        "label": "IN DEVELOPMENT",
        "value": 9
      },
      {
        "label": "REVIEW",
        "value": 10
      },
      {
        "label": "SEND TO CLIENT",
        "value": 11
      },
      {
        "label": "READY TO GO LIVE",
        "value": 12
      },
      {
        "label": "BOT LIVE",
        "value": 13
      },
      {
        "label": "FUTURE FOLLOW UP",
        "value": 14
      }
    ],
    "CONTACTING": 5,
    "CUSTOM": 4,
    "DATA_CAPTURE": 6,
    "FUTURE_FOLLOW_UP": 14,
    "IN_DEVELOPMENT": 9,
    "LOST": 3,
    "READY_TO_GO_LIVE": 12,
    "REVIEW": 10,
    "SALES_CALL_BOOKED": 7,
    "SEND_QUOTE": 8,
    "SEND_TO_CLIENT": 11,
    "STARTED": 1,
    "WON": 2
  },
  "StepOptionView": {
    "CHOICES": {
      "1": "List View",
      "2": "Grid View",
      "3": "Stars",
      "4": "Smiley",
      "5": "IFRAME",
      "6": "WINDOW",
      "7": "Not Applicable",
      "8": "Sleek View",
      "9": "Search View"
    },
    "CHOICE_LIST": [
      {
        "label": "List View",
        "value": 1
      },
      {
        "label": "Grid View",
        "value": 2
      },
      {
        "label": "Stars",
        "value": 3
      },
      {
        "label": "Smiley",
        "value": 4
      },
      {
        "label": "IFRAME",
        "value": 5
      },
      {
        "label": "WINDOW",
        "value": 6
      },
      {
        "label": "Not Applicable",
        "value": 7
      },
      {
        "label": "Sleek View",
        "value": 8
      },
      {
        "label": "Search View",
        "value": 9
      }
    ],
    "GRID_VIEW": 2,
    "IFRAME": 5,
    "LIST_VIEW": 1,
    "NA": 7,
    "SEARCH_VIEW": 9,
    "SLEEK_VIEW": 8,
    "SMILEY": 4,
    "STARS": 3,
    "WINDOW": 6
  },
  "SubscriptionChoice": {
    "ACTIVE": 1,
    "CANCEL": 2,
    "CHOICES": {
      "1": "Active",
      "2": "Cancel",
      "3": "Resume",
      "4": "Suspend",
      "5": "Unlock",
      "6": "Pause",
      "7": "Unpause"
    },
    "CHOICE_LIST": [
      {
        "label": "Active",
        "value": 1
      },
      {
        "label": "Cancel",
        "value": 2
      },
      {
        "label": "Resume",
        "value": 3
      },
      {
        "label": "Suspend",
        "value": 4
      },
      {
        "label": "Unlock",
        "value": 5
      },
      {
        "label": "Pause",
        "value": 6
      },
      {
        "label": "Unpause",
        "value": 7
      }
    ],
    "PAUSE": 6,
    "RESUME": 3,
    "SUSPEND": 4,
    "UNLOCK": 5,
    "UNPAUSE": 7
  },
  "SubscriptionPlanFeatureslist": {
    "CHOICES": {
      "hasAutomatedEmails": "Autoresponder Emails",
      "hasBasicIntegrations": "Basic Integrations",
      "hasConversationalButtons": "Conversational Buttons",
      "hasCustomEnquirybot": "Custom Enquirybot",
      "hasDynamicMessaging": "Dynamic Messaging",
      "hasExtraInfoFields": "Extra Info Fields",
      "hasFacebookLeadgen": "Facebook lead generation",
      "hasInboxResponse": "Inbox Response",
      "hasIntelligentEnquiryRouting": "Intelligent Enquiry Routing",
      "hasLandingPageBuilder": "Landing Page Builder",
      "hasNotes": "Notes",
      "hasPipelineAutomations": "Pipeline Automations",
      "hasProducts": "Products",
      "hasReplyTemplateAndFileLibrary": "Reply Template And File Library",
      "hasReportingDashboard": "Reporting Dashboard",
      "hasSalesForce": "Salesforce Integration",
      "hasSocialMediaIntegrations": "Social Media Integrations",
      "hasStripeIntegration": "Stripe Integration",
      "hasTaskDashboard": "Task Dashboard",
      "hasUnlimitedUser": "Unlimited User",
      "hasWebForms": "Web Forms",
      "noOfPipelines": "No Of Pipelines"
    },
    "CHOICE_LIST": [
      {
        "label": "Autoresponder Emails",
        "value": "hasAutomatedEmails"
      },
      {
        "label": "Basic Integrations",
        "value": "hasBasicIntegrations"
      },
      {
        "label": "Conversational Buttons",
        "value": "hasConversationalButtons"
      },
      {
        "label": "Custom Enquirybot",
        "value": "hasCustomEnquirybot"
      },
      {
        "label": "Dynamic Messaging",
        "value": "hasDynamicMessaging"
      },
      {
        "label": "Extra Info Fields",
        "value": "hasExtraInfoFields"
      },
      {
        "label": "Facebook lead generation",
        "value": "hasFacebookLeadgen"
      },
      {
        "label": "Inbox Response",
        "value": "hasInboxResponse"
      },
      {
        "label": "Intelligent Enquiry Routing",
        "value": "hasIntelligentEnquiryRouting"
      },
      {
        "label": "Landing Page Builder",
        "value": "hasLandingPageBuilder"
      },
      {
        "label": "Notes",
        "value": "hasNotes"
      },
      {
        "label": "Pipeline Automations",
        "value": "hasPipelineAutomations"
      },
      {
        "label": "Products",
        "value": "hasProducts"
      },
      {
        "label": "Reply Template And File Library",
        "value": "hasReplyTemplateAndFileLibrary"
      },
      {
        "label": "Reporting Dashboard",
        "value": "hasReportingDashboard"
      },
      {
        "label": "Salesforce Integration",
        "value": "hasSalesForce"
      },
      {
        "label": "Social Media Integrations",
        "value": "hasSocialMediaIntegrations"
      },
      {
        "label": "Stripe Integration",
        "value": "hasStripeIntegration"
      },
      {
        "label": "Task Dashboard",
        "value": "hasTaskDashboard"
      },
      {
        "label": "Unlimited User",
        "value": "hasUnlimitedUser"
      },
      {
        "label": "Web Forms",
        "value": "hasWebForms"
      },
      {
        "label": "No Of Pipelines",
        "value": "noOfPipelines"
      }
    ],
    "HAS_AUTOMATED_EMAILS": "hasAutomatedEmails",
    "HAS_BASIC_INTEGRATIONS": "hasBasicIntegrations",
    "HAS_CONVERSATIONAL_BUTTONS": "hasConversationalButtons",
    "HAS_CUSTOM_ENQUIRYBOT": "hasCustomEnquirybot",
    "HAS_DYNAMIC_MESSAGING": "hasDynamicMessaging",
    "HAS_EXTRA_INFO_FIELDS": "hasExtraInfoFields",
    "HAS_FACEBOOK_LEADGEN": "hasFacebookLeadgen",
    "HAS_INBOX_RESPONSE": "hasInboxResponse",
    "HAS_INTELLIGENT_ENQUIRY_ROUTING": "hasIntelligentEnquiryRouting",
    "HAS_LANDING_PAGE_BUILDER": "hasLandingPageBuilder",
    "HAS_NOTES": "hasNotes",
    "HAS_PIPELINE_AUTOMATIONS": "hasPipelineAutomations",
    "HAS_PRODUCTS": "hasProducts",
    "HAS_REPLY_TEMPLATE_AND_FILE_LIBRARY": "hasReplyTemplateAndFileLibrary",
    "HAS_REPORTING_DASHBOARD": "hasReportingDashboard",
    "HAS_SALES_FORCE": "hasSalesForce",
    "HAS_SOCIAL_MEDIA_INTEGRATIONS": "hasSocialMediaIntegrations",
    "HAS_STRIPE_INTEGRATION": "hasStripeIntegration",
    "HAS_TASK_DASHBOARD": "hasTaskDashboard",
    "HAS_UNLIMITED_USER": "hasUnlimitedUser",
    "HAS_WEB_FORMS": "hasWebForms",
    "NO_OF_PIPELINES": "noOfPipelines"
  },
  "SubscriptionPlanPipelineChoices": {
    "CHOICES": {
      "1": "Zero",
      "2": "Single",
      "3": "Multiple"
    },
    "CHOICE_LIST": [
      {
        "label": "Zero",
        "value": 1
      },
      {
        "label": "Single",
        "value": 2
      },
      {
        "label": "Multiple",
        "value": 3
      }
    ],
    "MULTIPLE": 3,
    "SINGLE": 2,
    "ZERO": 1
  },
  "SystemNotificationTypeChoices": {
    "CHOICES": {
      "1": "Enquiry",
      "2": "Task"
    },
    "CHOICE_LIST": [
      {
        "label": "Enquiry",
        "value": 1
      },
      {
        "label": "Task",
        "value": 2
      }
    ],
    "ENQUIRY": 1,
    "TASK": 2
  },
  "TaskCategoryColorsChoices": {
    "A": "#65BC55",
    "B": "#F1D52F",
    "C": "#FD9F31",
    "CHOICES": {
      "#127BBD": "#127BBD",
      "#20C2DE": "#20C2DE",
      "#354662": "#354662",
      "#59E79B": "#59E79B",
      "#65BC55": "#65BC55",
      "#C27BDE": "#C27BDE",
      "#E95B4C": "#E95B4C",
      "#F1D52F": "#F1D52F",
      "#FD7BCA": "#FD7BCA",
      "#FD9F31": "#FD9F31"
    },
    "CHOICE_LIST": [
      {
        "label": "#127BBD",
        "value": "#127BBD"
      },
      {
        "label": "#20C2DE",
        "value": "#20C2DE"
      },
      {
        "label": "#354662",
        "value": "#354662"
      },
      {
        "label": "#59E79B",
        "value": "#59E79B"
      },
      {
        "label": "#65BC55",
        "value": "#65BC55"
      },
      {
        "label": "#C27BDE",
        "value": "#C27BDE"
      },
      {
        "label": "#E95B4C",
        "value": "#E95B4C"
      },
      {
        "label": "#F1D52F",
        "value": "#F1D52F"
      },
      {
        "label": "#FD7BCA",
        "value": "#FD7BCA"
      },
      {
        "label": "#FD9F31",
        "value": "#FD9F31"
      }
    ],
    "D": "#E95B4C",
    "E": "#C27BDE",
    "F": "#127BBD",
    "G": "#20C2DE",
    "H": "#59E79B",
    "I": "#FD7BCA",
    "J": "#354662"
  },
  "TaskDashboardSectionChoices": {
    "CHOICES": {
      "1": "Overdue",
      "2": "Today",
      "3": "Tomorrow",
      "4": "Later",
      "5": "Completed",
      "6": "Today completed"
    },
    "CHOICE_LIST": [
      {
        "label": "Overdue",
        "value": 1
      },
      {
        "label": "Today",
        "value": 2
      },
      {
        "label": "Tomorrow",
        "value": 3
      },
      {
        "label": "Later",
        "value": 4
      },
      {
        "label": "Completed",
        "value": 5
      },
      {
        "label": "Today completed",
        "value": 6
      }
    ],
    "COMPLETED": 5,
    "LATER": 4,
    "OVERDUE": 1,
    "TODAY": 2,
    "TODAY_COMPLETED": 6,
    "TOMORROW": 3
  },
  "TaskKind": {
    "CALL": 4,
    "CHOICES": {
      "1": "TICK",
      "2": "EVENT",
      "3": "CLOCK",
      "4": "CALL",
      "5": "EMAIL"
    },
    "CHOICE_LIST": [
      {
        "label": "TICK",
        "value": 1
      },
      {
        "label": "EVENT",
        "value": 2
      },
      {
        "label": "CLOCK",
        "value": 3
      },
      {
        "label": "CALL",
        "value": 4
      },
      {
        "label": "EMAIL",
        "value": 5
      }
    ],
    "CLOCK": 3,
    "EMAIL": 5,
    "EVENT": 2,
    "TICK": 1
  },
  "TypeOfMesChoices": {
    "CHOICES": {
      "1": "New enquiry created",
      "2": "Mentioned in the enquiry",
      "3": "Enquiry Assigned",
      "4": "New reply from facebook",
      "5": "New reply from instagram",
      "6": "New reply from sms",
      "7": "New reply from email",
      "8": "Task assigned",
      "9": "Mentioned in the task",
      "10": "Mentioned in the task comment",
      "11": "Task reminder",
      "12": "Mentioned in the note has been updated",
      "13": "Mentioned in the note comment has been updated",
      "14": "New enquiry created from bot",
      "15": "New enquiry created from manully",
      "16": "New enquiry created sms",
      "17": "New enquiry created from facebook",
      "18": "New enquiry created from instagram",
      "19": "New enquiry created from Web forms",
      "20": "New enquiry created from fb leadgen"
    },
    "CHOICE_LIST": [
      {
        "label": "New enquiry created",
        "value": 1
      },
      {
        "label": "Mentioned in the enquiry",
        "value": 2
      },
      {
        "label": "Enquiry Assigned",
        "value": 3
      },
      {
        "label": "New reply from facebook",
        "value": 4
      },
      {
        "label": "New reply from instagram",
        "value": 5
      },
      {
        "label": "New reply from sms",
        "value": 6
      },
      {
        "label": "New reply from email",
        "value": 7
      },
      {
        "label": "Task assigned",
        "value": 8
      },
      {
        "label": "Mentioned in the task",
        "value": 9
      },
      {
        "label": "Mentioned in the task comment",
        "value": 10
      },
      {
        "label": "Task reminder",
        "value": 11
      },
      {
        "label": "Mentioned in the note has been updated",
        "value": 12
      },
      {
        "label": "Mentioned in the note comment has been updated",
        "value": 13
      },
      {
        "label": "New enquiry created from bot",
        "value": 14
      },
      {
        "label": "New enquiry created from manully",
        "value": 15
      },
      {
        "label": "New enquiry created sms",
        "value": 16
      },
      {
        "label": "New enquiry created from facebook",
        "value": 17
      },
      {
        "label": "New enquiry created from instagram",
        "value": 18
      },
      {
        "label": "New enquiry created from Web forms",
        "value": 19
      },
      {
        "label": "New enquiry created from fb leadgen",
        "value": 20
      }
    ],
    "ENQUIRY_ASSIGNED": 3,
    "ENQUIRY_CREATED": 1,
    "ENQUIRY_CREATED_FROM_BOT": 14,
    "ENQUIRY_CREATED_FROM_FACEBOOK": 17,
    "ENQUIRY_CREATED_FROM_FB_LEADGEN": 20,
    "ENQUIRY_CREATED_FROM_FORM": 19,
    "ENQUIRY_CREATED_FROM_INSTAGRAM": 18,
    "ENQUIRY_CREATED_FROM_MANUALLY": 15,
    "ENQUIRY_CREATED_FROM_SMS": 16,
    "ENQUIRY_HAS_BEEN_MENTIONED": 2,
    "NEW_REPLY_FROM_EMAIL": 7,
    "NEW_REPLY_FROM_FACEBOOK": 4,
    "NEW_REPLY_FROM_INSTAGRAM": 5,
    "NEW_REPLY_FROM_SMS": 6,
    "NOTE_COMMENT_HAS_BEEN_MENTIONED_UPDATE": 13,
    "NOTE_HAS_BEEN_MENTIONED_UPDATE": 12,
    "TASK_ASSIGNED": 8,
    "TASK_COMMENT_HAS_BEEN_MENTIONED": 10,
    "TASK_HAS_BEEN_MENTIONED": 9,
    "TASK_REMINDER": 11
  },
  "UserTaskSortByChoices": {
    "CHOICES": {
      "1": "PRIORITY",
      "2": "TIME"
    },
    "CHOICE_LIST": [
      {
        "label": "PRIORITY",
        "value": 1
      },
      {
        "label": "TIME",
        "value": 2
      }
    ],
    "PRIORITY": 1,
    "TIME": 2
  },
  "UserTypeOption": {
    "ADMIN": 1,
    "CHOICES": {
      "1": "Admin",
      "2": "Operations",
      "3": "Sales",
      "4": "Client Admin",
      "5": "Client User"
    },
    "CHOICE_LIST": [
      {
        "label": "Admin",
        "value": 1
      },
      {
        "label": "Operations",
        "value": 2
      },
      {
        "label": "Sales",
        "value": 3
      },
      {
        "label": "Client Admin",
        "value": 4
      },
      {
        "label": "Client User",
        "value": 5
      }
    ],
    "CLIENT_ADMIN": 4,
    "CLIENT_USER": 5,
    "OPERATIONS": 2,
    "SALES": 3
  },
  "WaitTypeChoice": {
    "CHOICES": {
      "1": "Hours",
      "2": "Days",
      "3": "Minutes"
    },
    "CHOICE_LIST": [
      {
        "label": "Hours",
        "value": 1
      },
      {
        "label": "Days",
        "value": 2
      },
      {
        "label": "Minutes",
        "value": 3
      }
    ],
    "DAYS": 2,
    "HOURS": 1,
    "MINUTES": 3
  }
};

export default CHOICES;
