import graphql from 'babel-plugin-relay/macro';

export const TopUnreadNotifications = graphql`
	query TopUnreadNotificationsQuery {
		systemNotificationsTopUnread {
			id
			description
			title
			createdAt
			url
			user {
				id
				name
			}
			isRead
		}
	}
`;
