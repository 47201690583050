import swal from 'sweetalert';

const alert = (title, subText, icon) => {
	return {
		title,
		text: subText,
		icon,
		buttons: {
			confirm: { text: 'Ok', className: 'swal-success' }
		},
		className: 'swal-copy'
	};
};

export const InfoAlert = (title, subText = '') => {
	return swal(alert(title, subText, 'success'));
};

export const ErrorAlert = (title, subText = '') => {
	return swal(alert(title, subText, 'error'));
};

export const WarningAlert = (title, subText = '') => {
	return swal(alert(title, subText, 'warning'));
};

export const DeleteAlert = (text = 'Once deleted, you will not be able to recover this data!') => {
	return swal({
		title: 'Are you sure?',
		text: text,
		icon: 'warning',
		buttons: true,
		dangerMode: true
	});
};

export const Confirmation = (title = '', subText = '', buttons = {}) => {
	return swal({
		title,
		text: subText,
		icon: 'warning',
		buttons: {
			confirm: { text: 'Ok', className: 'swal-success' },
			cancel: 'Cancel'
		},
		className: 'swal-copy',
		...buttons
	});
};
