import * as serviceWorker from 'serviceWorker';
import { Confirmation } from 'components/sweet-alert';

const confirmation = () => {
	Confirmation(
		'Update to the latest version',
		'There is a new version of EnquiryBot available to you',
		{
			buttons: {
				cancel: 'Dismiss',
				confirm: { text: 'Update', className: 'swal-success' }
			}
		}
	).then(() => {
		window.location.reload();
	});
};

const callback = (registration) => {
	const waitingServiceWorker = registration.waiting;
	if (waitingServiceWorker.onupdatefound) {
		waitingServiceWorker.addEventListener('statechange', (event) => {
			if (event.target.state === 'activated') {
				confirmation();
			}
		});
		waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
	} else {
		waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		if (waitingServiceWorker) {
			if (waitingServiceWorker.state === 'installed') {
				confirmation();
			}
		}
	}
};

export const register = () => {
	serviceWorker.register({
		onUpdate: callback,
		onSuccess: callback
	});
};

export const unregister = () => {
	serviceWorker.unregister();
};
