import React from 'react';

const Loader = ({ label, labelClassName, className }) => (
	<div className={`loader d-flex-center flex-column ${className ? className : ''}`}>
		{label && <span className={labelClassName ? labelClassName : ''}>{label}</span>}
		<div className="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
);

export default Loader;
